import { logReport } from "@/api/index";
import { LOG_KEY, EXCLUDE_LOG_KEY } from "@/utils/log-key.js";
import { isLocal } from "@/utils/util";
import { getSystemInfo, getCommonParams, getFileURL } from "./log-util";

// const connection =
//   navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// let network = connection.effectiveType || "unknown";
//
// function updateConnectionStatus() {
//   console.log(
//     "设备的网络连接从" + network + "变成了" + connection.effectiveType
//   );
//   network = connection.effectiveType || "unknown";
// }
// connection.addEventListener("change", updateConnectionStatus);

const log = {
  /**
   * 劫持全局console.log
   * @param args 参数
   */
  uid: "",
  commonParams: getCommonParams(),
  config() {},
  init() {
    const LOG_MAX = 20;
    let logs = [];
    const originalLog = console.log;
    const vm = this;
    window.console.log = function() {
      const args = Array.prototype.slice.call(arguments);
      originalLog(args);
      const log = [];
      let invalid = false;
      for (let arg of args) {
        let key = null;
        if (typeof arg === "undefined") {
          // do nothing
        } else if (typeof arg === "function") {
          key = arg.toString();
        } else {
          key = JSON.stringify(arg);
        }
        for (let invalid_key of EXCLUDE_LOG_KEY) {
          if (key && key.indexOf(invalid_key) != -1) {
            invalid = true;
            break;
          }
        }
        if (invalid) break;
        log.push(key);
      }
      if (log.length) {
        logs.push(log);
      }
      // 重置
      if (logs.length > LOG_MAX) {
        vm.addLog(10006, logs);
        logs = [];
      }
    };
    this.commonParams = getCommonParams();
  },
  /**
   * 输出日志
   * @param args 参数
   */
  info() {
    const args = Array.from(arguments);
    console.log([].concat(args));
    if (!args.length) return;
    this.addLog(10006, [].concat(args));
  },

  /**
   * 输出错误日志
   * @param args 参数
   */
  error() {
    const args = Array.from(arguments);
    console.error([].concat(args));
    for (const val in LOG_KEY) {
      if (arguments[0] && arguments[0].indexOf(LOG_KEY[val]) != -1) {
        this.addLog(40001, [].concat(args));
        break;
      }
    }
  },

  /**
   * 打点上报日志
   * @param  code 打点编码参数
   */
  addLog(code) {
    let args = Array.from(arguments);
    args = [code, location.href, args.slice(1)];
    const params = {
      ...getSystemInfo(),
      code,
      reportType: "step_log",
      from: "fe_pc",
      channel: 1,
      uid: this.uid,
      network: "unknown",
      fileURL: getFileURL(),
      clientIP: "unknown",
      commonParams: this.commonParams,
      createTime: Date.now(),
      extData: JSON.stringify(args),
      productchannel: 1
    };
    // alert(JSON.stringify(params, null, 2))
    if (isLocal) return;
    logReport(params).catch(err => {
      console.error(err);
      console.log("数据打点上报失败");
    });
  }
};
export default log;
