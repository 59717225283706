import request from "../common/js/request.js";

//用户登录
export const userLogin = (params = {}) => {
  return request.post("/adupc/user/login/tokenlogin", params);
};

//获取验证码
export const getVerifyCode = (params = {}) => {
  return request.post("/adupc/user/send/token", params);
};

// saas 检查手机号状态
export const saasCheckLogin = (params = {}) => {
  return request.post("/adupc/user/login/verify", params);
};

//查询用户章节
export const checkUserLesson = (params = {}) => {
  return request.post("/adupc/course/checkuserlesson", params);
};

// 记录直播间人数
export const signIn = (params = {}) => {
  return request.post("/aduwx/live/signIn", params);
};

// 请求0元课和未购买二维码
export const getCourseQrcode = (params = {}) => {
  return request.post("/aduwx/course/h5/qrcode", params);
};

// 获取用户信息
export const getUserInfo = (params = {}) => {
  return request.get("/adupc/v1/user/info", params);
};

// 用户登出
export const logout = (params = {}) => {
  return request.postData("/aduapp/v1/user/logout", params);
};

// 视频课观看时长上报
export const viewLogReport = (params = {}) => {
  return request.postData("/aduapp/v1/recordedLesson/viewLog/report", params);
};

// 获取视频课流地址
export const recordedLessonDetail = (params = {}) => {
  return request.postData("/aduapp/v1/recordedLesson/detail", params);
};

// 打点日志上报
export const logReport = (params = {}) => {
  return request.post("/aduwx/logreport/savelog", params, { channel: 1 });
};
