import Vue from "vue";

import { isMobile } from "@/utils/constant";
import { pageTo } from "@/utils/util";
import log from "@/utils/log";
import Browser from "../components/browser/App.vue";
import plugin from "../plugin/index.js";

export function initVue(App, router, store) {
  if (isMobile) {
    pageTo("mobile");
    return;
  }
  Vue.config.productionTip = false;
  Vue.config.devtools = process.env.NODE_ENV !== "production";
  const isIeBrowser =
    document.documentMode ||
    +(navigator.userAgent.match(/MSIE (d+)/) && RegExp.$1);
  const dom = isIeBrowser ? Browser : App;
  Vue.use(plugin);
  new Vue({
    router,
    store,
    render: h => h(dom),
    mounted() {
      log.init();
    }
  }).$mount("#app");
}
