import { zs } from "@zyb-data/stats-h5";
let plugin = {};
function func(to, from, next) {
  next(vm => {
    try {
      let fromRule, toRule;
      let base = vm.$router.options.base || "";
      base = base.replace(/\/$/, "");
      if (from.matched.length != 0) {
        fromRule = base + from.matched[from.matched.length - 1].path;
      }
      if (to.matched.length != 0) {
        toRule = base + to.matched[to.matched.length - 1].path;
      }
      fromRule = fromRule === undefined || fromRule === "" ? "/" : fromRule;
      toRule = toRule === undefined || toRule === "" ? "/" : toRule;
      zs.pageHide(fromRule);
      zs.pageShow(toRule);
    } catch (error) {
      console.log(error);
    }
  });
}
plugin.install = function(Vue) {
  Vue.mixin({
    beforeRouteEnter(to, from, next) {
      func(to, from, next);
    },
    beforeRouteUpdate(to, from, next) {
      func(to, from, next);
    }
  });
};
export default plugin;
