/**
 * @file supervisor/index.ts
 * 在 vue.config.js 作为 entry 引用了这个文件
 * This file is referenced at vue.config.js
 *
 */
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

const customExceptionName = "CustomException";

const DSN =
  "https://27ca1697ed274d7d83a547f332561d02@zbsentry.zuoyebang.com/28";
/**
 * 最多保存的操作记录数量
 */
const breadcrumbsLength = 15;

/**
 * 正式环境下开启监控
 */
if (/zybang|zuoyebang/.test(location.hostname)) {
  initSentry();
} else {
  console.info(
    "Sentry只在正式环境zybang.com域名下初始化, 本地和docker环境跳过"
  );
}

function initSentry() {
  /**
   * Sentry 初始化
   */
  Sentry.init({
    dsn: DSN,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
    ],
    beforeSend(event) {
      const breadcrumbs = event.breadcrumbs;
      /**
       * 对错误信息进行修改
       */
      let errorTag = "";
      if (Array.isArray(breadcrumbs)) {
        /**
         * 保存最近几条 breadcrumbs
         */
        event.breadcrumbs = breadcrumbs.slice(-1 * breadcrumbsLength);
        /**
         * 如果有访问过接口, 取最近一条的接口url。
         * 拼接到错误中。
         * file协议中接口数据都是通过 hybrid-sdk-common 获取, 这么处理在 file 协议下不生效
         */
        for (let i = event.breadcrumbs.length - 1; i > -1; i--) {
          if (
            event.breadcrumbs[i].category === "fetch" ||
            event.breadcrumbs[i].category === "xhr"
          ) {
            errorTag = (event.breadcrumbs[i].data || {}).url;

            if (errorTag) {
              // 取到接口 url, 截取调 query 部分
              errorTag = errorTag.replace(/\?.*$/, "");
            }
            break;
          }
        }
      }

      /**
       * 改写 exception type
       */
      if (
        event.message === customExceptionName &&
        event.exception &&
        Array.isArray(event.exception.values)
      ) {
        event.exception.values.forEach(val => {
          if (val.mechanism) {
            val.mechanism.synthetic = false;
          }
          if (errorTag) {
            val.type = `调用 ${errorTag} 接口出现异常`;
          }
        });
      }
      return event;
    }
  });

  Sentry.configureScope(async scope => {
    /**
     * Sentry 配置业务参数
     * 1. courseId
     * 2. lessonId
     */
    const regString = ["courseId", "lessonId"].join("|");

    const tags = {};

    try {
      /**
       * 页面参数来源分为两部分,
       * 有的页面是从 location.search 中获取,
       * 有的是拼接在 location.hash
       */
      const searchParamsFromSearch = new URLSearchParams(location.search);
      const queryStringFromHash = /\?.*$/.exec(location.hash);
      const searchParamsFromHash = new URLSearchParams(
        queryStringFromHash ? queryStringFromHash[0] : undefined
      );
      [searchParamsFromSearch, searchParamsFromHash].forEach(usp => {
        usp.forEach((v, k) => {
          if (new RegExp(regString, "i").test(k)) {
            tags[k] = v;
          }
        });
      });

      scope.setTags(tags);
    } catch (err) {
      throw Error(err);
    }
  });

  window.addEventListener("error", e => {
    /**
     * 捕获到 Script Error
     * 自定义上传
     */
    if (/Script\s+error/i.test(e.message)) {
      Sentry.captureException(customExceptionName);
    }
  });
}
