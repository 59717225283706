const UAParser = require("ua-parser-js");
import { stringify, parse } from "qs";
const UA = (navigator && navigator.userAgent) || "";
const parser = new UAParser(UA);

// 从UA获取系统信息
export const getSystemInfo = () => {
  const { name = "unknown", version = "unknown" } = parser.getOS();
  const {
    type = "unknown",
    vendor = "unknown",
    model = "unknown"
  } = parser.getDevice();
  return {
    os: name,
    osVersion: version,
    deviceType: `${type}_${vendor}_${model}`
  };
};

// 拼接上报的公共参数
const regString = ["courseId", "lessonId"].join("|");
export const getCommonParams = () => {
  const tags = [];
  const searchParamsFromSearch = new URLSearchParams(location.search);
  const queryStringFromHash = /\?.*$/.exec(location.hash);
  const searchParamsFromHash = new URLSearchParams(
    queryStringFromHash ? queryStringFromHash[0] : undefined
  );

  [searchParamsFromSearch, searchParamsFromHash].forEach(usp => {
    usp.forEach((v, k) => {
      if (new RegExp(regString, "i").test(k)) {
        tags[k] = v;
      }
    });
  });
  return stringify(tags);
};

// 格式化url path
export const getFileURL = () => {
  const query = parse(location.search.slice(1));
  const queryParamsStr = stringify(query, {
    delimiter: "_"
  });
  return `${location.host}${location.pathname}_${queryParamsStr}`;
};
