/* eslint-disable */

export const LOG_KEY = {
  REQCTRL_ERROR: 'course_cloud_reqctrl_error_detail_',
  INIT_ERROR: 'liveui_student_classroom_init_error_detail_',
  INIT_ROOM_ERROR: 'liveui_student_classroom_initroom_error_detail_',
  ADUWX_LIVE_SIGNIN: '/aduwx/live/signIn',
  SIGNALRESTORE_ERROR: 'liveui_student_classroom_signalRestore_error_detail_',
  GET_COURSE_SKU_SPU: 'aduwx_course_skuspuinfo',
  GET_LIVE_NOTICE: 'aduwx_live_notice',
  GET_SURVEY: 'aduwx_survey',
  GET_LPC_UID: 'aduwx_live_stulpc_update',
  GET_PULLSTREAM: '/adupc/live/pullstream'
}

// 剔除全埋点，播放器，聊天消息
export const EXCLUDE_LOG_KEY = [
  '_ZS_LOG_',
  '_ZS_SYS_',
  '加载视频中',
  '播放器可以播放',
  '播放器播放中',
  'chat message',
  'rev msg',
  '直播打点了',
  '计算拿到的消息',
  '#9#',
  'cur_ver_id',
  '播放时间片段'
]
