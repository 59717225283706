import qs from "qs";
import axios from "axios";
import { base } from "@/utils/util";
const config = {
  rex: {
    url: /^(http|https):\/\/(([a-zA-Z0-9._-]+\.[a-zA-Z]{2,6})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,4})*(\/[a-zA-Z0-9&%_./-~-]*)?/,
    phone: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
    allspace: /^\s+$/,
    mail: /^\w+@(?:\w+.zuoyebang.com)$/
  }
};

const baseUrl = "";

const axiosService = axios.create({
  baseURL: "/",
  timeout: 5000,
  withCredentials: true
});

axiosService.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosService.interceptors.response.use(
  (res = {}) => {
    const data = res.data || {};
    if (data) {
      // // 接口返回未登录跳转至登录页面
      if (data.errNo && (data.errNo === 201001 || data.errNo === 201002)) {
        window.location.replace(
          `${base}/index.html?page=gologin&returnUrl=${encodeURIComponent(
            location.href
          )}#/login`
        );
      }
      return data;
    } else {
      console.error(`未知错误`);
      return Promise.reject(data);
    }
  },
  (err = {}) => {
    if (err.response) {
      const res = err.response;
      const { data } = res;
      if (data) {
        console.error("服务器开小差啦~");
        return Promise.reject(data);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

const request = {
  /**
   * @name getData
   * @description get请求方法
   * @param { String } string 请求路径，如果是携带http/https的请求，不再对路径做处理
   * @param { Object } params 请求参数，端内的参数会拼接到url中
   * @param { Object } headers 请求头的设置
   * @return { Promise } 一个promise对象
   */
  getData: async (...args) => {
    let method = "get";
    let url = args[0];
    let params = args[1] || {};
    let headers = args[2] || {};
    if (!url) {
      return console.error("页面丢了！");
    }
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      ...headers
    };
    const urlReg = config.rex.url;
    if (!urlReg.test(url)) {
      url = baseUrl + url;
    }
    return axiosService({
      url,
      method,
      params,
      headers
    });
  },
  /**
   * @name postData
   * @description post请求方法
   * @param { String } string 请求路径，如果是携带http/https的请求，不再对路径做处理
   * @param { Object } params 请求参数
   * @param { Object } headers 请求头的设置
   * @return { Promise } 一个promise对象
   */
  postData: async (...args) => {
    let method = "post";
    let url = args[0];
    let data = args[1] || {};
    data = qs.stringify(data);
    let headers = args[2] || {};
    if (!url) {
      return console.error("页面丢了！");
    }
    headers = {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      ...headers
    };
    const urlReg = config.rex.url;
    if (!urlReg.test(url)) {
      url = baseUrl + url;
    }
    return axiosService({
      url,
      method,
      data,
      headers
    });
  },
  /**
   * @name get
   * @description get请求方法
   * @param { String } string 请求路径，如果是携带http/https的请求，不再对路径做处理
   * @param { Object } params 请求参数
   * @param { Object } headers 请求头的设置
   * @return { Promise } 一个promise对象
   */
  get: (...args) => {
    let method = "get";
    let url = args[0];
    let params = args[1] || {};
    let headers = args[2] || {};
    if (!url) {
      return console.error("页面丢了！");
    }
    headers = {
      "Content-Type": "application/json",
      ...headers
    };
    const urlReg = config.rex.url;
    if (!urlReg.test(url)) {
      url = baseUrl + url;
    }
    return axiosService({
      url,
      method,
      params,
      headers
    });
  },
  /**
   * @name post
   * @description post请求方法
   * @param { String } string 请求路径，如果是携带http/https的请求，不再对路径做处理
   * @param { Object } params 请求参数
   * @param { Object } headers 请求头的设置
   * @return { Promise } 一个promise对象
   */
  post: (...args) => {
    let method = "post";
    let url = args[0];
    let data = args[1] || {};
    let headers = args[2] || {};
    if (!url) {
      return console.error("页面丢了！");
    }
    headers = {
      "Content-Type": "application/json",
      ...headers
    };
    const urlReg = config.rex.url;
    if (!urlReg.test(url)) {
      url = baseUrl + url;
    }
    return axiosService({
      url,
      method,
      data,
      headers
    });
  }
};

export default request;
