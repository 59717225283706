import qs from "qs";
import { Loading } from "element-ui";
// import rootConfig from "../../config/index";

// export const isDocker = rootConfig.domain.indexOf("docker") > -1;
export const isDocker = !process.env.IS_PROD;
export const isLocal = process.env.NODE_ENV.indexOf("dev") > -1;
export const base = isLocal
  ? location.origin
  : `${location.origin}/static/cj-desktop`;

export function getUUID(len = 32) {
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz1234567890";
  const maxPos = chars.length;
  let pwd = [];
  for (let i = 0; i < len; i++) {
    pwd.push(chars.charAt(Math.floor(Math.random() * maxPos)));
  }
  return pwd.join("");
}

export function pageTo(page = "index", params) {
  const query = qs.parse(location.search.slice(1));
  const urlParams = {
    ...query,
    ...params
  };
  const url = `${base}/${page}.html?${qs.stringify(urlParams)}`;
  window.location.replace(url);
}

/**
 *
 * @desc 拼接参数跳转页面
 * 拼接url参数透传防止参数丢失
 */
export function goTo(page = "live", params) {
  const query = qs.parse(location.search.slice(1));
  const { playback = 0 } = params;
  const urlParams = {
    ...query,
    ...params
  };
  // 伪直播，回放。录播，https协议需要替换成http协议
  let origin = location.origin;
  const isHttps = /(https):\/\/([\w.]+\/?)\S*/.test(origin);
  if (playback != 0 && isHttps) {
    origin = origin.replace("https", "http");
  }
  // 这一块url hash参数里面的&符号不能丢，方便后续获取查询参数
  const url = `${origin}${location.pathname}?${qs.stringify(
    urlParams
  )}#/${page}?${qs.stringify(urlParams)}`;
  window.location.replace(url);
}
export const createRandom = (m, n) => {
  let c = m - n + 1;
  return Math.floor(Math.random() * c + n);
};

export const isInFullScreen = () => {
  const doc = window.document;
  return (
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
};

// 跳转到登陆页面且成功以后回跳到原页面
export function goLogin(page = "index", returnUrl = "my", params) {
  const query = qs.parse(location.search.slice(1));
  let urlParams = {
    ...query,
    ...params
  };
  const url = `${base}/${returnUrl}.html?${qs.stringify(urlParams)}`;
  window.location.replace(
    `${base}/${page}.html?page=gologin&returnUrl=${encodeURIComponent(
      url
    )}#/login`
  );
}

// 跳转到直播间
export function goLive(data) {
  const { courseId, lessonId, playback } = data;
  const url = `${base}/index.html?courseId=${courseId}&lessonId=${lessonId}&playback=${playback}`;
  window.open(url);
}

/**
 *
 * 百度云图片2.0处理
 * @param {*} src 图片地址
 * @param {*} w 缩放宽度
 * @param {*} h 缩放高度
 * @param {*} s 缩放比例
 * https://cloud.baidu.com/doc/BOS/s/Ek1rl4doa
 */
export const resizeImage = (src, w = 84, h = 84, s = 3) => {
  if (!src) return;
  // 仅对百度云图片处理缩放
  const baiduCloudDomain = [
    "https://img.zuoyebang.cc",
    "https://img1.zuoyebang.cc",
    "https://img2.zuoyebang.cc",
    "https://bd-s.zuoyebang.cc",
    "https://charge.zuoyebang.cc",
    "https://test-charge.zuoyebang.cc"
  ];
  const isBaiduCloudDomain = baiduCloudDomain.filter(domain => {
    return new RegExp(domain).test(src);
  });
  if (!isBaiduCloudDomain) return;
  // 兼容多端图片大小统一为设计稿的3倍
  const symbol = src.includes("?") ? "&" : "?";
  return `${src}${symbol}x-bce-process=image/resize,m_fill,w_${w * s},h_${h *
    s}`;
};

let loading = null;
export const startLoading = (scence = "body") => {
  loading = Loading.service({
    lock: true,
    text: "加载中···",
    customClass: "loading-container",
    background: "rgba(255, 255, 255)",
    target: document.querySelector(scence)
  });
};

export const endLoading = () => {
  loading && loading.close();
};
