// 跳转路由
export const PAGE_ROUTER_MAP = {
  // 登录页
  LOGIN_PAGE: "gologin",
  // 直播间/回放间
  LIVE_ROOM_PAGE: "live"
};
const UA = (navigator && navigator.userAgent) || "";
// 判断微信环境
export const isInWeiXin = /micromessenger/i.test(UA);
// 判断是否在移动端环境
export const isMobile = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i.test(
  UA
);
// 判断是否在IE浏览器
export const isIeBrowser =
  document.documentMode ||
  +(navigator.userAgent.match(/MSIE (d+)/) && RegExp.$1);

// 直播间错误码
export const DIALOG_INFO = {
  LIVE_END: 2, //直播结束
  NO_LOGIN: 3, //未登录
  INIT_ERR: 4 //直播间接口异常
};

// 打点间隔
export const Z_LOG_DURASTION = 1000 * 60 * 3;
export const Z_LOG_CHECK_TIME = 1000 * 60;

// 非法课程状态
export const INVALID_COURSE_STATUS = [1, 3, 5];

// 加载视频等待时间
export const LOAD_VIDEO_DURATION = 15 * 1000;

// 续点播放提前时间
export const RELOAD_VIDEO_FORWARD_TIME = 3;
// 后端返回课程类型
export const COURSE_PLAYTYPE_LIVE = 1;
export const COURSE_PLAYTYPE_PLAYBACK = 2;
export const COURSE_PLAYTYPE_RECORDLESSON = 3;

export const PLAYBACK_TYPE_LIST = [
  COURSE_PLAYTYPE_PLAYBACK,
  COURSE_PLAYTYPE_RECORDLESSON
];
export const LIVE_PLAYBACK_TYPE_LIST = [
  COURSE_PLAYTYPE_LIVE,
  COURSE_PLAYTYPE_PLAYBACK,
  COURSE_PLAYTYPE_RECORDLESSON
];

// 直播间跳转类型
export const PLAYTYPE_LIVE = 0;
export const PLAYTYPE_PLAYBACK = 1;
export const PLAYTYPE_RECORDLESSON = 2;

// 从后端给的 playType 映射成前端业务课程类型playback
export const PLAYBACK_MAP = {
  PLAYTYPE_LIVE: 1,
  PLAYTYPE_PLAYBACK: 2,
  PLAYTYPE_RECORDLESSON: 3
};

// 从后端给的lessonStatus 映射成前端业务课程类型
export const LESSONSTATUS_MAP = {
  LIVENOTSTART_LESSON_STATUS: 1, // 未开始,
  VIDEOCREATING_LESSON_STATUS: 3, // 回放生成中
  LIVEBROADCAST_LESSON_STATUS: 2, // 直播中
  VIDEOCOMPLETE_LESSON_STATUS: 4, // 回放已完成
  NOPLAYBACK_LESSON_STATUS: 5, // 无回放
  REOPENED_LESSON_STATUS: 6, // 重开
  EXPIRED_LESSON_STATUS: 7, // 失效
  PLAYBACK_LESSON_STATUS: 8 // 看回放
};
